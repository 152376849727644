export { default as getPlatform } from './src/getPlatform';
export { default as useConfiguration } from './src/hooks/useConfiguration';
export {
  default as usePlatform,
  PlatformProvider,
} from './src/context/usePlatform';
export {
  default as PlatformConfigProvider,
  usePlatformConfigContext,
} from './src/context/usePlatformConfig';
export * from './src/constants';
export { PLATFORMS, ENVIRONMENTS } from '../../../environments/globalConstants';
