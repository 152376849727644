import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'ActionButtons' })(() => ({
  root: {},
  button: {
    margin: 'auto 0px',
  },
  actionLinkContainer: {
    paddingBottom: 'var(--ebl-s2)',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
  },
  actionLinkContainerMultiple: {
    justifyContent: 'space-between',
  },
  signInDetailsLink: {
    textDecoration: 'none !important',
  },
  signInDetailsButton: {
    marginRight: 0,
    marginLeft: 0,
  },
}));
