import React from 'react';
import Icon from '@hmhco/icon';
import infoSvg from '@ed/baseline/icons/cts/info-sm.svg';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useStyles from './RequiredFieldsNote.styles';

const RequiredFieldsNote = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Icon
        svg={infoSvg}
        className={classes.icon}
        size="16"
        aria-hidden="true"
        colour="var(--ebl-signal-info)"
      />
      <Typography>
        <FormattedMessage id="tenantPicker.requiredFields" />
      </Typography>
    </div>
  );
};

export default RequiredFieldsNote;
