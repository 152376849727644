import React from 'react';
import { Skeleton, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from '@hmhco/icon';
import changeSvgMd from '@ed/baseline/icons/cts/change-md.svg';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import useStyles from './SelectedOrganization.styles';

const getTitleId = hasInternational =>
  hasInternational
    ? 'tenantPicker.selectedOrganization'
    : 'tenantPicker.selectedDistrictIndependentSchool';

const getChangeAriaLabelId = hasInternational =>
  hasInternational
    ? 'tenantPicker.changeOrganizationAriaLabel'
    : 'tenantPicker.changeDistrictIndependentSchoolAriaLabel';

const SelectedOrganization = ({
  organizationName,
  isInternational,
  onClick,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const showOnDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const titleId = getTitleId(isInternational);
  const changeAriaLabelId = getChangeAriaLabelId(isInternational);

  if (!organizationName) {
    return (
      <div
        className={classes.root}
        data-testid="selected-organization-skeleton"
      >
        <Skeleton
          variant="text"
          width="16.75rem"
          height="var(--ebl-sh2-line-height)"
          className={classes.headerSkeleton}
        />
        <Skeleton
          variant="text"
          height="var(--ebl-p2-line-height)"
          width="13.75rem"
          className={classes.organizationSkeleton}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Typography component="h2" variant="subtitle2" className={classes.header}>
        <FormattedMessage id={titleId} />
      </Typography>
      <div className={classes.organization}>
        <Typography variant="body2">{organizationName}</Typography>
        {onClick && (
          <Button
            size="small"
            className={classes.button}
            startIcon={
              <Icon
                svg={changeSvgMd}
                size="16"
                colour="var(--ebl-button-frameless-text-color)"
              />
            }
            aria-label={formatMessage({
              id: changeAriaLabelId,
            })}
            variant="text"
            fullWidth={false}
            onClick={onClick}
          >
            {showOnDesktop && <FormattedMessage id="tenantPicker.change" />}
          </Button>
        )}
      </div>
    </div>
  );
};

SelectedOrganization.propTypes = {
  organizationName: PropTypes.string,
  isInternational: PropTypes.bool,
  onClick: PropTypes.func,
};

SelectedOrganization.defaultProps = {
  organizationName: undefined,
  isInternational: undefined,
  onClick: undefined,
};

export default SelectedOrganization;
