import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useBootstrapOrgInfo from '../../../../hooks/bootstrapForgotPasswordOrgInfo.hook';
import ForgotPasswordHeading from './ForgotPasswordHeading';
import ForgotPasswordUsername from './ForgotPasswordUsername';
import ForgotPasswordButtons from './ForgotPasswordButtons';
import {
  getShouldRenderErrorScreen,
  getShouldRenderForgotPasswordForm,
  getShouldRenderSuccessScreen,
} from '../../../../store/selectors/ForgotPasswordPageState';
import TenantPickerLayout from '../../Layout/TenantPickerLayout';
import Logo from '../../Logo/Logo';
import useStyles from './ForgotPasswordPage.styles';
import {
  getForgotPasswordIsInternational,
  getForgotPasswordOrgName,
  getIsForgotPasswordDisabled,
} from '../../../../store/selectors/ForgotPasswordOrg';
import SelectedOrganization from '../../SelectedOrganization/SelectedOrganization';

const ForgotPasswordPage = () => {
  const { classes } = useStyles();
  const renderErrorState = useSelector(getShouldRenderErrorScreen);
  const renderSuccessState = useSelector(getShouldRenderSuccessScreen);
  const renderForgotPasswordForm = useSelector(
    getShouldRenderForgotPasswordForm,
  );
  const forgotPasswordDisabled = useSelector(getIsForgotPasswordDisabled);
  const organizationName = useSelector(getForgotPasswordOrgName);
  const isInternational = useSelector(getForgotPasswordIsInternational);

  useBootstrapOrgInfo();

  return (
    <TenantPickerLayout>
      <header>
        <Logo />
        <ForgotPasswordHeading />
      </header>
      <main className={classes.main}>
        {renderForgotPasswordForm && (
          <>
            {!forgotPasswordDisabled && (
              <>
                <Typography variant="body2" className={classes.helpText}>
                  <FormattedMessage id="tenantPicker.forgotPassword.helpText" />
                </Typography>
                <br />
                <Typography variant="body2" className={classes.studentHelpText}>
                  <FormattedMessage id="tenantPicker.forgotPassword.studentHelpText" />
                </Typography>
              </>
            )}
            <SelectedOrganization
              organizationName={organizationName}
              isInternational={isInternational}
            />
            <ForgotPasswordUsername />
          </>
        )}
        {renderErrorState && (
          <SelectedOrganization
            organizationName={organizationName}
            isInternational={isInternational}
          />
        )}
        {renderSuccessState && (
          <Typography variant="body2">
            <FormattedMessage id="tenantPicker.forgotPassword.successMessage" />
          </Typography>
        )}
        <ForgotPasswordButtons />
      </main>
    </TenantPickerLayout>
  );
};

export default ForgotPasswordPage;
