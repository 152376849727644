import { getUserCtx } from '@hmhco/amp-core/src/userContext/auth';
import { PLATFORMS } from '../../../../environments/globalConstants';
import { getUiPlatform } from '../../../../environments/getUiPlatform';
import { getHcpContextWrapper, appendCurentPlatform } from './utils';

/**
 * Returns a user context Object, encapsulated in a Promise for FLIGHT and SUPPORT_TOOL
 *
 * The user context object contains a `uiPlatform` member.
 * The information for `uiPlatform` comes from `currentConfiguration.js`
 *
 * @param {String} platform user context type by platform. Platforms can be found in `globalConstants.js`
 * @returns {Object|Promise} Object or Promise encapsulating a user context object.
 */

export const getContextForPlatform = platform => {
  if (platform === PLATFORMS.ED) {
    // sync function
    return appendCurentPlatform(getUserCtx());
  }
  if (platform === PLATFORMS.FLIGHT || platform === PLATFORMS.SUPPORT_TOOL) {
    // async function
    return getHcpContextWrapper(platform);
  }
  throw Error(`platform: "${platform}" is not supported`);
};

/**
 * Returns `getContextForPlatform()`, if `platform` is not passed it will default to using the value of `currentConfiguration.js`.
 *
 * @param {String} [platform] user context type by platform. Optional.
 * @returns {Function} getContextForPlatform()
 */

export const getContextForCurrentPlatform = (platform = getUiPlatform()) => {
  return getContextForPlatform(platform);
};

/**
 * Returns `getContextForPlatform()`, if `platform` is not passed it will default to `PLATFORMS.ED`.
 *
 * @param {String} [platform] user context type by platform. Optional.
 * @returns {Function} getContextForPlatform()
 */

export default (platform = PLATFORMS.ED) => {
  return getContextForPlatform(platform);
};
