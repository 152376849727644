import gql from 'graphql-tag';

export const getUserContextQuery = gql`
  query getUserContext($id: String!) {
    getUserById(idType: "DBID", id: $id) {
      users {
        id
        givenName
        familyName
        email
        memberships {
          id
          role
          group {
            id
            parentId
            name
            account {
              id
              accountType
              dealerId
              domainId
              source
              accountQualifier {
                key
                value
              }
              accountAltId {
                altKeyType
                altKey
              }
            }
          }
        }
      }
    }
  }
`;

export const getUserContextQueryWithGroupMembershipDealer2 = gql`
  query getUserContext($id: String!) {
    getUserById(idType: "DBID", id: $id) {
      users {
        domain {
          rootAccounts {
            accountAltId {
              altKey
              altKeyType
            }
          }
        }
        id
        givenName
        familyName
        email
        groupMemberships {
          capabilitiesGroup(args: { filters: { types: "private" } }) {
            id
            type
            linkedAccounts {
              account {
                id
                accountType
                dealerId
                domainId
                source
                accountAltId {
                  altKey
                  altKeyType
                }
                accountQualifier {
                  key
                  value
                }
              }
            }
          }
          capabilities {
            key
            value
          }
        }
      }
    }
  }
`;

export const getUserContextQueryWithGroupMembershipDealer1 = gql`
  query getUserContext($id: String!) {
    getUserById(idType: "DBID", id: $id) {
      users {
        id
        givenName
        familyName
        email
        groupMemberships {
          capabilitiesGroup {
            id
            type
            linkedAccounts {
              account {
                id
                accountType
                dealerId
                domainId
                source
                accountAltId {
                  altKey
                  altKeyType
                }
                accountQualifier {
                  key
                  value
                }
              }
            }
          }
          capabilities {
            key
            value
          }
        }
      }
    }
  }
`;
