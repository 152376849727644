const currentPort =
  (typeof window !== 'undefined' && window?.location?.port) || '9002';

const devEdConfig = {
  name: 'local',
  ampContent: 'https://local.hmhone.hmhco.com:9002/content',
  ampLoggedInURL: `https://local.hmhone.hmhco.com:${currentPort}/ui/logged-in/`,
  ampSignedInURL: `https://local.hmhone.hmhco.com:${currentPort}/ui/signed-in/`,
  ampLogin: 'https://local.hmhone.hmhco.com:9002/ui/login/',
  ampLoginHelp: 'http://downloads.hmlt.hmco.com/Help/Ed/LogIn',
  ampLoginPrivacyPolicy:
    'https://www.hmhco.com/prek-12-products-privacy-policy',
  ampLoginTermsAndConditions:
    'https://www.hmhco.com/terms-of-use-k12-learning-platforms',
  hnmLoginHelp: 'http://downloads.hmlt.hmco.com/Help/Flight/LogIn/',
  hnmLoginPrivacyPolicy:
    'https://www.heinemann.com/privacy/?utm_medium=shorturl&utm_source=privacy',
  hnmLoginTermsAndConditions:
    'https://www.heinemann.com/platforms-terms-of-use/?utm_medium=shorturl&utm_source=platform-terms',
  apiEngHostname: 'https://api.int.br.internal',
  apiEngHostnamePublic: 'https://api.int.br.internal',
  assessments: 'https://local.hmhone.hmhco.com:9002/api/assessment',
  assignments: 'https://local.hmhone.hmhco.com:9002/api/assignment',
  authorizationApi: 'https://local.hmhone.hmhco.com:9002/api/authorization',
  auth0: 'https://hmhco-eng.auth0.com',
  auth0CreateConnectionsHost:
    'http://auth-self-service-setup.int.br.hmheng.io/api/v1/sso',
  auth0FetchConnectionsHost:
    'http://auth-self-service-setup.int.br.hmheng.io/api/v1/sso/datastore/connection',
  baseURL: 'https://local.hmhone.hmhco.com',
  classcraftSessionData:
    'https://hapi.int.hmhco.com/classcraft-session-data-svc',
  classes: 'https://local.hmhone.hmhco.com:9002/ui/classes',
  classesClasses: 'https://local.hmhone.hmhco.com:9002/ui/classes/classes',
  clientRegistry: 'https://api.int.eng.hmhco.com/idm-client-registry',
  collaboration: 'collaboration.dev.hmhco.com',
  contentProvider: 'https://local.hmhone.hmhco.com:9002/ui/#/contentProvider/',
  districtDownloadApi:
    'https://data-district-download-service.staging0.hmheng-ds.br.internal',
  districtDownloadApiK8s:
    'https://hapi.int.hmhco.com/data-district-download-service/',
  ebrCore: 'https://api.int.br.internal/idm-ebr-core',
  ebrUi: 'http://int.hmhone.app.hmhco.com/ebr/',
  edHostname: 'http://int.hmhone.app.hmhco.com/',
  edRoot: 'https://local.hmhone.hmhco.com:9000/#/',
  edBaseUrl: 'https://local.hmhone.hmhco.com:9002',
  EDSLoginUrl: 'https://app01.int.tsg.hmhco.com/edcore/eds-login',
  EDSResourceUrl:
    'https://app01.int.tsg.hmhco.com/edcore/hmheng-eds-api-gateway',
  grading: 'https://local.hmhone.hmhco.com:9002/api/grading/',
  gradingK8s: 'https://app01.int.tsg.hmhco.com/grading-api/',
  hmhHelpCenter: 'http://downloads.hmlt.hmco.com',
  hmoHostname: 'http://my-test.hrw.com/access/',
  hmoLoginPath: 'http://my-test.hrw.com/index.jsp',
  idm: 'http://int.app.sif.identity.api.hmhco.com/openid-connect',
  jasperReporting: 'https://int.hmhone.app.hmhco.com/jasperserver-pro',
  licenses: 'https://local.hmhone.hmhco.com:9002/ui/#/licenses',
  liftRoot: 'https://local.hmhone.hmhco.com:9002/ui/',
  loginPath: `:${currentPort}/ui/login/`,
  ltiCache: 'https://api.int.eng.hmhco.com/lti-deep-linking/cache',
  myClasses: 'https://local.hmhone.hmhco.com:9002/ui/!my-classes',
  myStuff: 'https://local.hmhone.hmhco.com:9002/ui/#/my-stuff/lessons',
  notificationsApi: 'https://api.int.eng.hmhco.com',
  newNotificationsApi: 'https://hapi.int.hmhco.com',
  nweaBaseUrl: 'https://auth.int.nwea.org/adult/login',
  nweaStartPage: 'https://int03-start.mapnwea.org',
  nweaCommonRosterRedirectUrl:
    'https://int03-admin.mapnwea.org/admin/importprofile/StartNewImportPage.seam',
  oktaConnectionApi:
    'https://api.int.br.internal/okta-services-gateway/api/v1/sso',
  oktaFetchConnectionsHost:
    'https://api.int.br.internal/okta-services-gateway/api/v1/sso/idps',
  oktaNewOrg: 'https://int.hmhco.com/okta-services-gateway/api/v1/org', // this is deliberate to prevent a 401 logout on int
  onesearch: '/api/onesearch/v1',
  picker: 'https://local.hmhone.hmhco.com:9002/ui/#/picker/',
  plannerHost: 'https://planner-api.staging0.hmheng-csl.br.internal',
  plannerHostNonWaf: 'https://planner-api.staging0.hmheng-csl.br.internal',
  plsAuthors:
    'https://api.int.eng.hmhco.com/pls-services-author/api/v5/authors',
  plsCollections:
    'https://api.int.eng.hmhco.com/pls-services-resource/api/v5/collections',
  plsLessonRelatedResource:
    'https://api.int.eng.hmhco.com/pls-services-lessonrelatedresource/api/v5/lessonrelatedresources',
  plsRelatedResources:
    'https://api.int.eng.hmhco.com/pls-services-relatedresource/api/v5/relatedresources',
  plsResource:
    'https://api.int.eng.hmhco.com/pls-services-resource/api/v5/resources',
  edsApiGateway: 'https://hapi.dev.hmhco.com/edcore/hmheng-eds-api-gateway/',
  prefixCDS: 'http://cds-metadatapipeline.int.br.hmheng.io',
  reportDownload: '/api/report/v5/programs/schoolAssessment',
  reportDownloadK8s:
    'https://app01.int.tsg.hmhco.com/reporting-api/v5/programs/schoolAssessment',
  restApi: 'https://api.int.eng.hmhco.com',
  restGroupLessonApi: 'https://practice-qa.gogetwaggle.com/QA1/Teacher',
  restHapi: 'https://hapi.int.hmhco.com',
  restDMPS: 'https://api.int.br.internal/dmps',
  restDMPSNonWAF: 'https://api.int.br.internal/dmps',
  restELM: 'https://api.int.br.internal/elm/v1',
  restIdsApi: 'https://api.int.br.internal/ids', // for purging users in IDS we need a different API
  restIdsGridApi: 'https://api.int.br.internal/ids-grid-api',
  restIdsGridApiNonWAF: 'https://api.int.br.internal/ids-grid-api',
  restOAC: 'https://api.int.br.internal/oac/v3',
  restOACNonWAF: 'https://api.int.br.internal/oac/v3',
  restOacV2: 'http://int.hmhone.app.hmhco.com/api/oac/v2',
  restPostPrefix: 'http://int.hmhone.app.hmhco.com',
  restPrefix: 'http://int.hmhone.app.hmhco.com',
  restRecommendation: 'https://api.int.br.internal/recommendation-api',
  restStagingProcessor: 'https://api.int.br.internal/idm-staging-processor',
  restSusApi: 'https://api.int.br.internal/idm-user-api', // for purging users in SUS
  restUdsApi: 'https://api.int.eng.hmhco.com/uds',
  restUpdatesApi: 'https://api.int.eng.hmhco.com/update-catalog',
  rosterDashboard: 'https://local.hmhone.hmhco.com:9002/ui/#/roster',
  r180Api: 'https://local.hmhone.hmhco.com:9002/api/r180/',
  r180Media: 'https://int.hmhone.app.hmhco.com/r180/media',
  sisHostname: 'http://int.hmhone.app.hmhco.com/',
  sso: 'http://auth-self-service-setup.int.br.hmheng.io',
  ssoInitializeUrl: 'http://api.int.br.internal/okta-ed-sp/initialize',
  storyBook: 'http://localhost:9009/',
  susAuth: '/api/authorization',
  standardsReportWritableResources: '',
  standardsReportWaggleResources: '',
  tcHostname: 'http://www-int.thinkcentral.com/access/',
  tcLoginPath: 'http://www-int.thinkcentral.com/ePC/start.do',
  linkedAssignments:
    'https://local.hmhone.hmhco.com:9002/ui/#/assign-full?resourceId=l_ff9bdd36-f627-4241-8dbc-9f9b4f158f56_c7b06f27-597b-42fc-8169-67247568e97f&programId=IM_NL20_G4&title=Unit+Opener%3A+Place+Value+and+Whole+Number+Operations&backTo=discover%252FIM_NL20_G4%252F1&showSched=true&recBy=NONE&isSgm=false',
  virtualClassroomApi:
    'https://api.int.eng.hmhco.com/vcservice/v2/virtual-classroom/meeting',
  wormhole: 'https://api.int.br.internal/wh-api/graphql',
  wormholek8s: 'https://hapi.int.hmhco.com/wormhole-api/graphql',
  importingService: 'https://api.int.br.internal/idm-api-importing-service',
  licensesRemoteEntrypoint: 'https://local.hmhone.hmhco.com:9022/nlEntry.js',
  apiBaseURL: '',
  featureFlagURL:
    'https://local.hmhone.hmhco.com:9002/ui/flags/featureFlagsCohorts.config.json',
  flightLogin: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/`,
  forgotPassword: '/ui/login/forgot-password/',
  requestSigninDetails: '/ui/login/request-signin-details/',
  edsAuthorize:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/authorize',
  edsAuthorizationCode:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/authorization-code/callback',
  edsInitUrl:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
  plsCollaborationArtifacts:
    'https://app01.int.tsg.hmhco.com/collaboration-artifacts-svc',
  planktonBaseUrl: 'https://d16qzots3do3wk.cloudfront.net/',
  edpsUrl: 'https://hapi.int.hmhco.com/edcore/hmheng-eds-api-gateway/',
  plsCoachingTopicURL:
    'https://api.int.eng.hmhco.com/pls-services-resource/api/v1',
  createStudentUrl:
    'https://app01.int.tsg.hmhco.com/ids-grid-api/ids/v1/students',
  hnmGeneratedUrl: 'https://platform-content-share.dev.heinemann.com/',
};

module.exports = devEdConfig;
