import React from 'react';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { bool, func } from 'prop-types';
import useStyles from './CreateAccountButton.styles';

const CreateAccountButton = ({ onClick: handleRegisterUser, isDisabled }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Button
        onClick={handleRegisterUser}
        disabled={isDisabled}
        variant="contained"
        size="small"
        color="primary"
        type="submit"
        className={classes.button}
      >
        <FormattedMessage id="tenantPicker.register.createAccountButton" />
      </Button>
    </div>
  );
};

CreateAccountButton.propTypes = {
  onClick: func,
  isDisabled: bool,
};

export default CreateAccountButton;
