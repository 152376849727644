import React from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import LinkToNewTab from '@hmhco/link-to-new-tab/src/LinkToNewTab';
import { usePlatform } from '@hmhco/platform-helper';
import { PLATFORMS, getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import useStyles from './OrderNumberLink.styles';

const getHelpTextId = platform => {
  switch (platform) {
    case PLATFORMS.FLIGHT:
      return 'registerPage.link.help.orderNumber.flight';
    default:
      return 'registerPage.link.help.orderNumber';
  }
};

const OrderNumberLink = () => {
  const { classes, cx } = useStyles();
  const platform = usePlatform();
  const linkText = getHelpTextId(platform);
  const { hmhHelpCenter } = getConfigForCurrentEnv(PLATFORMS.ED);
  const registerAdminHelpLink = `${hmhHelpCenter}/Help/Ed/Administrator/index.htm#t=Basics%2FRegister_an_Account.htm`;

  return (
    <Typography variant="body2" className={classes.root}>
      <FormattedMessage id={`${linkText}.aria`}>
        {ariaLabel => (
          <LinkToNewTab
            className={cx(classes.link, {
              [classes.linkHeinemann]: platform === PLATFORMS.FLIGHT,
            })}
            href={registerAdminHelpLink}
            aria-label={ariaLabel}
          >
            <FormattedMessage id={linkText} />
          </LinkToNewTab>
        )}
      </FormattedMessage>
    </Typography>
  );
};

export default OrderNumberLink;
