import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import { currentRouteIntegration } from '../../../../../environments/environment';

const requestSignInDetailsUrl = `/ids/v1/users/signin-notification`;
const requestSignInDetailsUrlHnm = `/ids/v1/users/signin-notification`;

export default (pactConfig = {}) => {
  const { client, cancel, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });
  const { restIdsGridApi } = getConfigForCurrentEnv(currentRouteIntegration());
  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    baseURL: restIdsGridApi,
    pactConfig,
  });
  return {
    requestSignInDetailsEd: ({ email, districtId }) =>
      request.post(
        requestSignInDetailsUrl,
        JSON.stringify({ email, districtId, platform: 'Ed' }),
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      ),
    cancel,
    requestSignInDetailsHnm: ({ email, districtId }) =>
      request.post(
        requestSignInDetailsUrlHnm,
        JSON.stringify({ email, districtId, platform: 'Hnm' }),
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      ),
  };
};
