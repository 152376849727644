import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { string } from 'prop-types';
import TranslationMessageProvider from '@hmhco/i18n-react/src/TranslationsMessageProvider';
import ctsDefaultTheme from '@hmhco/cts-default-theme';
import TopLevelThemeProvider from '@hmhco/toplevel-theme-provider';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { PLATFORM_THEMES } from '@hmhco/platform-helper';
import ErrorBoundary from './components/ErrorBoundaryComponent';
import { defaultTranslations, getLocaleFile } from './lang';
import rootReducer from './store/reducers';
import { currentRouteIntegration } from '../../../../environments/environment';
import LegacyRoutes from './components/LegacyRoutes';
import TenantPickerRoutes from './components/TenantPickerRoutes';
import { CONFIGURATIONS } from '../../../../environments/globalConstants';

export const store = createStore(rootReducer);

const App = ({ languageKey }) => {
  const configuration = CONFIGURATIONS[currentRouteIntegration()].name;

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter basename="/ui/login/">
          <TranslationMessageProvider
            locale={languageKey}
            messageFetchFunction={getLocaleFile}
            defaultTranslations={defaultTranslations}
          >
            {messages => (
              <IntlProvider locale={languageKey} messages={messages}>
                <TopLevelThemeProvider
                  theme={PLATFORM_THEMES[configuration] || ctsDefaultTheme}
                >
                  <Switch>
                    <Route
                      path="/tenant-picker"
                      render={({ match }) => (
                        <TenantPickerRoutes
                          url={match.url}
                          configuration={configuration}
                        />
                      )}
                    />
                    <Route
                      path="/"
                      render={({ match }) => (
                        <LegacyRoutes
                          url={match.url}
                          configuration={configuration}
                        />
                      )}
                    />
                  </Switch>
                </TopLevelThemeProvider>
              </IntlProvider>
            )}
          </TranslationMessageProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
};

App.propTypes = {
  languageKey: string,
};

App.defaultProps = {
  languageKey: 'en-US',
};

export default App;
