import ctsDefaultTheme from '@hmhco/cts-default-theme';
import flightTheme from '@hmhco/flight-theme';
import { PLATFORMS } from '../../../../environments/globalConstants';

export const PLATFORM_THEMES = {
  [PLATFORMS.ED]: ctsDefaultTheme,
  [PLATFORMS.SUPPORT_TOOL]: ctsDefaultTheme,
  [PLATFORMS.FLIGHT_CONTENT_SHARE]: flightTheme,
  [PLATFORMS.FLIGHT]: flightTheme,
};

export const PLATFORM_BASE_NAME = {
  [PLATFORMS.FLIGHT]: 'ui/#',
};
