import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import { currentRouteIntegration } from '../../../../../environments/environment';

const getOrgUrl = orgPid => `/ids/v1/organizations/pid/${orgPid}`;

export default (pactConfig = {}) => {
  const { restIdsGridApi } = getConfigForCurrentEnv(currentRouteIntegration());
  const { client, cancel, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });
  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    baseURL: restIdsGridApi,
    pactConfig,
  });
  return {
    getOrgInfo: orgPid => request.get(getOrgUrl(orgPid)),
    cancel,
  };
};
