import { getConfig } from './buildAuthenticationUrl';
import { getSignedInUrl } from './buildAuthenticationUrlHnm';

function buildAuthCallback(code, state, codeVerifier) {
  const { edsAuthorizationCode } = getConfig();

  const params = new URLSearchParams();
  params.append('state', state);
  params.append('interaction_code', code);
  params.append('redirect_uri', getSignedInUrl());
  params.append('code_verifier', codeVerifier);

  return `${edsAuthorizationCode}?${params.toString()}`;
}

export default buildAuthCallback;
