import React from 'react';
import Typography from '@mui/material/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ALERT_SIZE, HcpAlert, SEVERITY } from '@hmhco/hcp-alert';
import useStyles from './ForgotPasswordHeading.styles';
import { getIsForgotPasswordDisabled } from '../../../../store/selectors/ForgotPasswordOrg';
import RequiredFieldsNote from '../../RequiredFieldsNote/RequiredFieldsNote';
import {
  getShouldRenderErrorScreen,
  getShouldRenderSuccessScreen,
} from '../../../../store/selectors/ForgotPasswordPageState';

const getTitleId = renderSuccessState => {
  if (renderSuccessState) {
    return 'tenantPicker.forgotPassword.successTitle';
  }
  return 'login.forgotPassword.pageTitle';
};

const ForgotPasswordHeading = () => {
  const { formatMessage } = useIntl();
  const { classes } = useStyles();
  const forgotPasswordDisabled = useSelector(getIsForgotPasswordDisabled);
  const renderErrorState = useSelector(getShouldRenderErrorScreen);
  const renderSuccessState = useSelector(getShouldRenderSuccessScreen);
  const titleId = getTitleId(renderSuccessState);

  return (
    <div className={classes.root}>
      {renderErrorState && (
        <HcpAlert
          size={ALERT_SIZE.SMALL}
          severity={SEVERITY.ERROR}
          title={formatMessage({
            id: 'tenantPicker.errorTitle',
          })}
          subtitle={formatMessage({
            id: 'login.requestSiginDetails.errorMessage',
          })}
          action={null}
          className={classes.error}
        />
      )}
      {forgotPasswordDisabled && (
        <HcpAlert
          size={ALERT_SIZE.SMALL}
          severity={SEVERITY.ERROR}
          title={formatMessage({
            id: 'tenantPicker.errorTitle',
          })}
          subtitle={formatMessage({
            id: 'login.forgotPassword.forgotPasswordDisabledMessage',
          })}
          action={null}
          className={classes.error}
        />
      )}
      <Typography component="h1" variant="h3">
        <FormattedMessage id={titleId} />
      </Typography>
      {!forgotPasswordDisabled && !renderErrorState && !renderSuccessState && (
        <RequiredFieldsNote />
      )}
    </div>
  );
};

export default ForgotPasswordHeading;
