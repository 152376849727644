import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import { Authorize, getPkceCodeVerifier } from '@hmh/eds-login-js';
import { setCachedOrgInfo } from '../store/actions/CountryStateOrgActions';
import getOrgInfoClient from '../api/getOrgInfo';
import { getStateFromStateCode } from '../constants/usStates';
import { setDidPidFail } from '../store/actions/TenantPickerActions';
import { setConnectionPid } from '../storageHelpers/localStorageAccessors';
import { setCachedOrgInfo as cacheOrgInfo } from '../storageHelpers/sessionStorageAccessors';
import { getClientId, NON_SSO_ISSUER } from '../constants/constants';
import { getConfig } from '../api/buildAuthenticationUrl';
import { getSignedInUrl } from '../api/buildAuthenticationUrlHnm';

export const handleOrgInfo = (dispatch, orgPid) => {
  const { getOrgInfo } = getOrgInfoClient();
  const { edsInitUrl } = getConfig();
  getOrgInfo(orgPid)
    .then(orgInfo => {
      const orgState = getStateFromStateCode(orgInfo.stateCode);
      const country = orgInfo.countryCode
        ? { code: orgInfo.countryCode, name: orgInfo.countryName }
        : { code: 'US', name: 'United States' };
      const newState = {
        data: {
          country,
          state: orgState,
          org: { pid: orgPid, name: orgInfo.name, orgRefId: orgInfo.orgId },
        },
      };
      dispatch(setCachedOrgInfo(newState));

      cacheOrgInfo(orgPid, newState.data);
      setConnectionPid(orgPid);

      getPkceCodeVerifier('S256').then(({ challenge, verifier }) => {
        sessionStorage.setItem('verifier', verifier);
        sessionStorage.setItem('challenge', challenge);
      });

      const authorize = new Authorize(
        getClientId(),
        NON_SSO_ISSUER,
        edsInitUrl,
        {
          connection: orgPid,
          targetLinkUri: getSignedInUrl(),
          login_hint: orgInfo.orgId,
        },
      );

      authorize
        .getThirdPartyLoginUri({ loginHint: orgInfo.orgId })
        .then(loginInitUri => {
          window.location = loginInitUri;
        });
    })
    .catch(error => {
      dispatch(setDidPidFail(true));
      logErrorWithContext('Login app - Error in Organization info', [
        { key: 'errorMessage', value: error },
      ]);
    });
};

export default handleOrgInfo;
