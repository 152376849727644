import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import { currentRouteIntegration } from '../../../../../environments/environment';

const url = '/user/resetPassword';
const changePasswordApi = (pactConfig = {}) => {
  const { client, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });
  const { authorizationApi: baseURL } = getConfigForCurrentEnv(
    currentRouteIntegration(),
  );
  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    baseURL,
    pactConfig,
  });
  return {
    changePassword: ({ username, password, token, platformId }) => {
      const payload = {
        username,
        password,
        token,
        platformId,
      };
      return request.post(url, payload);
    },
  };
};

export default changePasswordApi;
