const devHnmConfig = {
  name: 'dev',
  edsLoginLandingPage:
    'https://platform.dev.heinemann.com/ui/login/landing-page',
  contentShareAppUrl: 'https://platform-content-share.dev.heinemann.com/',
  edsLogoutUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-login/logout',
  edsPostLogoutRedirectUrl: 'https://platform.dev.heinemann.com/',
  flightRosteredLoginUrl: 'https://platform.dev.heinemann.com/ui/login/',
  edsLoginClientId: '2cc7842e-ed1b-401e-b203-c2756a26d795',
  edsLoginUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
  edsLoginIssuer: 'https://hnm-dev.authorization.heinemann.com',
  edsGraphUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/hmheng-eds-api-gateway/graphql',
  edsLtiUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti',
  edLtiUrl: 'https://api.int.br.internal/lti-platform',
  rceProtocolHost: 'http://int.hmhone.app.hmhco.com',
  planktonBaseUrl: 'https://d16qzots3do3wk.cloudfront.net/',
  isPlanktonPreview: true,
  edsContentShareClientId: 'b9f2100f-4f3a-449d-8ab7-26b3fd1856ec',
  edsContentShareInitializeUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti/launch/share-link',
  edsContentShareAuthorizeUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti/authorize',
  edsContentShareTokenUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti/token',
  licensesRemoteEntrypoint: 'http://localhost:9999',
  restUdsApi: 'https://api.int.eng.hmhco.com/uds/',
  featureFlagURL:
    'https://platform.dev.heinemann.com/featureFlagsCohorts.flight.json',
  heinemannBaseURL: 'https://platform.dev.heinemann.com',
  requestSigninDetails: '/ui/login/request-signin-details/',
  liftRoot: 'https://local.hmhone.hmhco.com:9002/ui/',
  jasperReporting: 'https://int.hmhone.app.hmhco.com/jasperserver-pro',
  ampLoggedInURL: 'https://local.hmhone.hmhco.com:9002/ui/logged-in/',
  ampSignedInURL: `https://local.hmhone.hmhco.com:9002/ui/signed-in/`,
  edsInitUrl:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
  wormhole: 'https://api.int.br.internal/wh-api/graphql',
  wormholek8s: 'https://app01.int.tsg.hmhco.com/wormhole-api/graphql',
  apiBaseURL: '',
  restStagingProcessor: 'https://api.int.br.internal/idm-staging-processor',
  flightLogin: `https://platform.dev.heinemann.com/ui/`,
  EDSLoginUrl: 'https://app01.int.tsg.hmhco.com/edcore/eds-login',
  edsAuthorizationCode:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/authorization-code/callback',
  authorizationApi: 'https://local.hmhone.hmhco.com:9002/api/authorization',
  restIdsGridApi: 'https://api.int.br.internal/ids-grid-api',
  susAuth: '/api/authorization',
  ampLogin: 'https://local.hmhone.hmhco.com:9002/ui/login/',
  ltiCache: 'https://api.int.eng.hmhco.com/lti-deep-linking/cache',
};

module.exports = devHnmConfig;
