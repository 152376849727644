import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import { currentRouteIntegration } from '../../../../../environments/environment';

const forgotPasswordUrl = `/user/forgotPassword`;
const forgotPasswordUrlHnm = `/user/forgotPassword`;

export default (pactConfig = {}) => {
  const { authorizationApi } = getConfigForCurrentEnv(
    currentRouteIntegration(),
  );
  const { client, cancel, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });
  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    baseURL: authorizationApi,
    pactConfig,
  });
  return {
    forgotPassword: ({ username, districtId }) =>
      request.post(
        forgotPasswordUrl,
        JSON.stringify({ username, districtId, platform: 'Ed' }),
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      ),
    cancel,
    forgotPasswordHnm: ({ username, districtId }) =>
      request.post(
        forgotPasswordUrlHnm,
        JSON.stringify({ username, districtId, platformId: 'flight' }),
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      ),
  };
};
