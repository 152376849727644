const { PLATFORMS, CONFIGURATIONS } = require('./globalConstants');
const { getUiPlatform } = require('./getUiPlatform');

/**
 * Checks URL and defaults to either ED or FLIGHT
 * Useful for UI code deployed to a single location
 * that serves multiple URLs/platforms (such as login)
 * so we're keeping it for Login purposes
 * and if we want to switch to this pattern (single deploy, multiple hostnames) for the platform
 */
const getCurrentConfigurationTheme = () => {
  const hasPredicate = window.location.host.includes(
    CONFIGURATIONS[PLATFORMS.FLIGHT].predicate,
  );
  if (hasPredicate) {
    return PLATFORMS.FLIGHT;
  }
  return PLATFORMS.ED;
};

/**
 * Only used by login apps currently
 * On environments, it checks the URL and returns either ED or FLIGHT
 *
 * On local it uses the hardcoded configuration (ui platform)
 */
const currentRouteIntegration = () => {
  const isLocal = window.location.host.includes('local');
  if (isLocal) {
    return getUiPlatform() || getCurrentConfigurationTheme();
  }
  return getCurrentConfigurationTheme();
};

module.exports = {
  currentRouteIntegration,
  getCurrentConfigurationTheme,
};
