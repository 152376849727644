import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { PLATFORMS, getConfigForCurrentEnv } from '@hmhco/amp-core-env';

const getActiveCountriesUrl = '/ids/v1/countries?status=active';
const getActiveStatesUrl = countryCode =>
  `/ids/v1/countries/${countryCode}/states?status=active`;
const getOrgsForCountryAndStateUrl = (
  countryCode,
  stateCode,
  searchTerm = '',
) =>
  `/ids/v1/countries/${countryCode}/states/${stateCode}/orgs?term=${encodeURIComponent(
    searchTerm,
  )}`;

const getOrgsForCountryUrl = (countryCode, searchTerm = '') =>
  `/ids/v1/countries/${countryCode}/orgs?term=${encodeURIComponent(
    searchTerm,
  )}`;

export default (pactConfig = {}) => {
  const { client, cancel, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });
  /**
   * this was previously calling getPlatform() which in it's initial
   * implementation, always returned ED when there is no user context
   */
  const { restIdsGridApi } = getConfigForCurrentEnv(PLATFORMS.ED);
  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    baseURL: restIdsGridApi,
    pactConfig,
  });
  return {
    getActiveCountries: () => request.get(getActiveCountriesUrl),
    getActiveStates: countryCode =>
      request.get(getActiveStatesUrl(countryCode)),
    getOrgsForCountryAndState: (countryCode, stateCode, searchTerm) =>
      request.get(
        getOrgsForCountryAndStateUrl(countryCode, stateCode, searchTerm),
      ),
    getOrgsForCountry: (countryCode, searchTerm) =>
      request.get(getOrgsForCountryUrl(countryCode, searchTerm)),
    cancel,
  };
};
