import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'RegisterUserPage' })({
  heading: {
    paddingBottom: 'var(--ebl-s3)',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > *': {
      marginBottom: 'var(--ebl-s2) !important',
    },
  },
  error: {
    marginBottom: 'var(--ebl-s3)',
  },
});
