import { ForgotPasswordOrgTypes, ForgotPasswordStateTypes } from './types';

export const setOrgName = orgName => ({
  type: ForgotPasswordOrgTypes.FORGOT_PASSWORD_UPDATE_ORG_NAME,
  orgName,
});

export const setIsInternational = isInternational => ({
  type: ForgotPasswordOrgTypes.FORGOT_PASSWORD_SET_IS_INTERNATIONAL,
  isInternational,
});

export const setForgotPasswordDisabledFlag = () => ({
  type: ForgotPasswordOrgTypes.FORGOT_PASSWORD_SET_DISABLED_FLAG,
});

export const setForgotPasswordOrgError = () => ({
  type: ForgotPasswordOrgTypes.FORGOT_PASSWORD_SET_ORG_ERROR,
});

export const setForgotPasswordUsername = username => ({
  type: ForgotPasswordStateTypes.FORGOT_PASSWORD_UPDATE_USERNAME,
  username,
});

export const setForgotPasswordLoading = () => ({
  type: ForgotPasswordStateTypes.FORGOT_PASSWORD_REQUEST_IS_LOADING,
});

export const setForgotPasswordError = () => ({
  type: ForgotPasswordStateTypes.FORGOT_PASSWORD_REQUEST_HAS_ERROR,
});

export const setForgotPasswordSuccess = () => ({
  type: ForgotPasswordStateTypes.FORGOT_PASSWORD_REQUEST_IS_SUCCESS,
});
