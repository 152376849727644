import React, { useEffect } from 'react';
import { Authorize } from '@hmh/eds-login-js';
import { useDocumentHelper } from '@hmhco/document-helper';
import { useIntl } from 'react-intl';
import { usePlatform } from '@hmhco/platform-helper';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import { Stack, Typography } from '@mui/material';
import { getSSOConfiguration } from '../../constants/constants';
import TenantPickerLayout from '../TenantPicker/Layout/TenantPickerLayout';
import Logo from '../TenantPicker/Logo/Logo';

/**
 * handleSSOError
 * Handles errors that occur during EDS login SSO authentication.
 * @param {Object} params - The parameters object.
 * @param {Error} params.error - The error object representing the SSO error.
 * @param {string} params.platform - The platform where the error occurred.
 * @returns {void}
 */
export const handleSSOError = ({ error, platform, loginUri }) => {
  logErrorWithContext(
    `EDS Login: ${platform} Login app - Error Retrieving Third Party Login URI`,
    [{ key: 'errorMessage', value: error }],
  );
  window.location.assign(loginUri);
};

/**
 * Component representing the Single Sign-On (SSO) flow.
 * @param {Object} props - The props object.
 * @param {'HNM' | 'HMH'} props.platform - The current theme for the SSO flow.
 * @param {String} props.loginUri - The login page uri we should redirect back to.
 * @returns {JSX.Element} The JSX element to kickstart the SSO flow.
 */
const SSOFlow = () => {
  const { formatMessage } = useIntl();
  const platform = usePlatform();
  const message = formatMessage({ id: 'ssoPage.redirect.title' }, { platform });
  useDocumentHelper(message);

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.has('iss')) {
      const {
        clientId,
        baseUri,
        loginUri,
        targetLinkUri,
      } = getSSOConfiguration(platform);

      const authorize = new Authorize(clientId, search.get('iss'), baseUri, {
        targetLinkUri,
      });

      authorize
        .getThirdPartyLoginUri()
        .then(loginInitUri => window.location.assign(loginInitUri))
        .catch(error =>
          handleSSOError({
            platform,
            error,
            loginUri,
          }),
        );
    }
  }, [platform]);

  return (
    <main>
      <TenantPickerLayout>
        <Stack
          alignItems="center"
          spacing="var(--ebl-s2)"
          sx={{ width: '100%' }}
        >
          <Logo />
          <Typography component="h1" variant="h3">
            {message}
          </Typography>
        </Stack>
      </TenantPickerLayout>
    </main>
  );
};

export default SSOFlow;
