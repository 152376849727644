import { Route, Switch } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { PlatformProvider } from '@hmhco/platform-helper';
import SSOFlow from './SSOFlow/SSOPage';
import ForgotPasswordPage from './ForgotPasswordPage/ForgotPasswordPage';
import RequestSignInDetailsPage from './RequestSignInDetailsPage/RequestSignInDetailsPage';
import NewPasswordPage from './NewPasswordPage/NewPasswordPage';
import RegisterUser from './RegisterUser/RegisterUser';
import LoginPage from './LoginPage/LoginPage';
import { QUERY_PARAMS } from '../constants/constants';

const LegacyRoutes = ({ url, configuration }) => {
  const path = url === '/' ? url : `${url}/`;

  return (
    <Switch>
      <Route path={`${path}sso`}>
        <PlatformProvider platform={configuration}>
          <SSOFlow />
        </PlatformProvider>
      </Route>
      <Route path={`${path}forgot-password`}>
        <ForgotPasswordPage currentTheme={configuration} />
      </Route>
      <Route path={`${path}request-signin-details`}>
        <RequestSignInDetailsPage currentTheme={configuration} />
      </Route>
      <Route path={`${path}new-password`}>
        <NewPasswordPage currentTheme={configuration} />
      </Route>
      <Route path={`${path}register`}>
        <RegisterUser currentTheme={configuration} />
      </Route>
      <Route
        path={url}
        render={match => {
          const redirectedFrom = new URLSearchParams(match.location.search).get(
            QUERY_PARAMS.FROM,
          );

          return (
            <LoginPage
              redirectedFrom={redirectedFrom}
              currentTheme={configuration}
            />
          );
        }}
      />
    </Switch>
  );
};

LegacyRoutes.propTypes = {
  url: PropTypes.string.isRequired,
  configuration: PropTypes.string.isRequired,
};

export default LegacyRoutes;
