import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'Heading' })(() => ({
  link: {
    whiteSpace: 'nowrap',
    '& span': {
      marginBottom: '0.125rem',
      marginLeft: 6,
    },
    [`&:visited`]: {
      color: 'var(--ebl-link-visited-color) !important',
    },
    [`&:visited > *`]: {
      fill: 'var(--ebl-link-visited-color) !important',
    },
  },
  linkHeinemann: {
    '& svg': {
      fill: 'var(--ebl-dark-action-color)',
    },
  },
}));
