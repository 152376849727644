const THEMES = {
  heinemann: 'heinemann',
  hmh: 'hmh',
};

const ED_DEFAULT_CONFIGURATION = {
  name: 'HMH',
  predicate: 'hmh',
  showDesktopNavbar: true,
  showMobileNavbar: true,
  showLMS: true,
  showUpdatesBanner: true,
  theme: THEMES.hmh,
};

const FLIGHT_DEFAULT_CONFIGURATION = {
  name: 'HNM',
  predicate: 'heinemann',
  showDesktopNavbar: false,
  showMobileNavbar: false,
  showLMS: false,
  showUpdatesBanner: false,
  theme: THEMES.heinemann,
};

const PLATFORMS = {
  ED: 'HMH',
  FLIGHT: 'HNM',
  FLIGHT_CONTENT_SHARE: 'HNM-CONTENT-SHARE',
  SUPPORT_TOOL: 'SUPPORT-TOOL',
};

/**
 * to ensure backwards compatibility, we basically serve the same config objects we did before
 * This pattern leaves us space to integrate other configs later
 */

const CONFIGURATIONS = {
  [PLATFORMS.ED]: ED_DEFAULT_CONFIGURATION,
  [PLATFORMS.FLIGHT]: FLIGHT_DEFAULT_CONFIGURATION,
  [PLATFORMS.FLIGHT_CONTENT_SHARE]: FLIGHT_DEFAULT_CONFIGURATION,
  [PLATFORMS.SUPPORT_TOOL]: ED_DEFAULT_CONFIGURATION,
};

const ENVIRONMENTS = {
  local: 'local',
  dev: 'dev',
  int: 'int',
  cert: 'cert',
  devCert: 'devCert',
  prod: 'prod',
};

module.exports = {
  PLATFORMS,
  ENVIRONMENTS,
  CONFIGURATIONS,
  THEMES,
};
