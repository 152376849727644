import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'RequestSigninDetailsInformationHeader' })({
  root: {
    paddingBottom: 'var(--ebl-s3)',
  },
  errors: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--ebl-s2)',
  },
  hasError: {
    marginBottom: 'var(--ebl-s3)',
  },
});
