import {
  LOCAL_STORAGE_CONNECTION_KEY,
  LOCAL_STORAGE_NONCE_KEY,
} from '../constants/storageConstants';

function setLocalStorageValue(key, value) {
  try {
    localStorage.setItem(key, value);
  } catch {
    // We ignore as this is likely that login is being accessed from iframe which is unsupported
  }
}

function getLocalStorageValue(key) {
  try {
    return localStorage.getItem(key);
  } catch {
    return null;
  }
}

export function setConnectionPid(pid) {
  setLocalStorageValue(LOCAL_STORAGE_CONNECTION_KEY, pid);
}

export function getConnectionPid() {
  return getLocalStorageValue(LOCAL_STORAGE_CONNECTION_KEY);
}

export function setNonce(nonce) {
  setLocalStorageValue(LOCAL_STORAGE_NONCE_KEY, nonce);
}

export function getNonce() {
  return getLocalStorageValue(LOCAL_STORAGE_NONCE_KEY);
}
