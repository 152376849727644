const certHnmConfig = {
  name: 'cert',
  edsLoginLandingPage:
    'https://platform.cert.heinemann.com/ui/login/landing-page',
  contentShareAppUrl: 'https://platform-content-share.cert.heinemann.com/',
  edsLogoutUrl: 'https://api.cert.heinemann.com/login/logout',
  edsPostLogoutRedirectUrl: 'https://platform.cert.heinemann.com/',
  flightRosteredLoginUrl: 'https://platform.cert.heinemann.com/ui/login/',
  edsLoginClientId: '2cc7842e-ed1b-401e-b203-c2756a26d795',
  edsLoginUrl: 'https://api.cert.heinemann.com/login/login/initializeUri',
  edsLoginIssuer: 'https://platform-cert.authorization.heinemann.com',
  edsGraphUrl: 'https://api.cert.heinemann.com/graphql',
  edsLtiUrl: 'https://api.cert.heinemann.com/lti/lti',
  edLtiUrl: 'https://api.cert.hmhco.com/lti-platform',
  rceProtocolHost: 'https://cert.hmhco.com',
  planktonBaseUrl: 'https://d16qzots3do3wk.cloudfront.net/',
  isPlanktonPreview: false,
  edsContentShareClientId: '2432c4e2-921a-460a-b938-7c44fc7d516e',
  edsContentShareInitializeUrl:
    'https://api.cert.heinemann.com/lti/lti/launch/share-link',
  edsContentShareAuthorizeUrl:
    'https://api.cert.heinemann.com/lti/lti/authorize',
  edsContentShareTokenUrl: 'https://api.cert.heinemann.com/lti/lti/token',
  licensesRemoteEntrypoint: 'http://localhost:9999',
  restUdsApi: 'https://api.cert.hmhco.com/uds/',
  featureFlagURL:
    'https://platform.cert.heinemann.com/featureFlagsCohorts.flight.json',
  heinemannBaseURL: 'https://platform.cert.heinemann.com',
  doTheMathBaseUrl: 'https://platform.cert.heinemann.com/ui/login',
  requestSigninDetails: '/ui/login/request-signin-details/',
  liftRoot: 'https://platform.cert.heinemann.com/ui/',
  jasperReporting: 'https://api.cert.heinemann.com/jasperserver-pro',
  ampLoggedInURL: 'https://cert.hmhco.com/ui/logged-in/',
  ampSignedInURL: 'https://platform.cert.heinemann.com/ui/signed-in/',
  edsInitUrl:
    'https://api.cert.heinemann.com/edcore/eds-login/login/initializeUri',
  wormhole: 'https://api.cert.hmhco.com/wh-api/graphql',
  wormholek8s: 'https://app01.cert.tsg.hmhco.com/wormhole-api/graphql',
  apiBaseURL: 'https://cert.hmhco.com',
  restStagingProcessor: 'https://api.cert.hmhco.com/idm-staging-processor',
  flightLogin: `https://platform.cert.heinemann.com/ui/login/`,
  EDSLoginUrl: 'https://api.cert.heinemann.com/edcore/eds-login',
  edsAuthorizationCode:
    'https://api.cert.heinemann.com/edcore/eds-login/login/authorization-code/callback',
  authorizationApi: 'https://cert.hmhco.com/api/authorization',
  restIdsGridApi: 'https://api.cert.hmhco.com/ids-grid-api',
  susAuth: 'https://cert.hmhco.com/api/authorization',
  ampLogin: 'https://platform.cert.heinemann.com/ui/login/',
  ltiCache: 'https://api.cert.hmhco.com/lti-deep-linking/cache',
};

module.exports = certHnmConfig;
