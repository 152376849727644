const currentPort =
  (typeof window !== 'undefined' && window?.location?.port) || '9002';

const localHnmConfig = {
  name: 'local',
  edsServiceUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-core-app',
  edsLoginLandingPage: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/landing-page`,
  contentShareAppUrl: 'https://local.hmhone.hmhco.com:9003/',
  edsLogoutUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-login/logout',
  edsPostLogoutRedirectUrl: `https://local.hmhone.hmhco.com:${currentPort}/`,
  flightRosteredLoginUrl: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/`,
  edsLoginClientId: '2cc7842e-ed1b-401e-b203-c2756a26d795',
  edsLoginUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
  edsLoginIssuer: 'https://hnm-int.authorization.heinemann.com',
  edsGraphUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/hmheng-eds-api-gateway/graphql',
  edsLtiUrl: 'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti',
  edLtiUrl: 'https://api.dev.br.internal/lti-platform',
  rceProtocolHost: 'http://int.hmhone.app.hmhco.com',
  planktonBaseUrl: 'https://d16qzots3do3wk.cloudfront.net/',
  isPlanktonPreview: true,
  edsContentShareClientId: 'b9f2100f-4f3a-449d-8ab7-26b3fd1856ec',
  edsContentShareInitializeUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti/launch/share-link',
  edsContentShareAuthorizeUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti/authorize',
  edsContentShareTokenUrl:
    'https://app01.dev.tsg.hmhco.com/edcore/eds-lti/lti/token',
  licensesRemoteEntrypoint: 'https://local.hmhone.hmhco.com:9022/nlEntry.js',
  restUdsApi: 'https://api.int.eng.hmhco.com/uds/',
  featureFlagURL: `https://local.hmhone.hmhco.com:${currentPort}/featureFlagsCohorts.flight.json`,
  heinemannBaseURL: 'https://local.hmhone.hmhco.com:9002',
  requestSigninDetails: '/ui/login/request-signin-details/',
  jasperReporting: 'https://int.hmhone.app.hmhco.com/jasperserver-pro',
  liftRoot: 'https://local.hmhone.hmhco.com:9002/ui/',
  ampLoggedInURL: `https://local.hmhone.hmhco.com:${currentPort}/ui/logged-in/`,
  ampSignedInURL: `https://local.hmhone.hmhco.com:${currentPort}/ui/signed-in/`,
  edsInitUrl:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/initializeUri',
  wormhole: 'https://api.int.br.internal/wh-api/graphql',
  wormholek8s: 'https://app01.int.tsg.hmhco.com/wormhole-api/graphql',
  apiBaseURL: '',
  restStagingProcessor: 'https://api.int.br.internal/idm-staging-processor',
  flightLogin: `https://local.hmhone.hmhco.com:${currentPort}/ui/login/`,
  EDSLoginUrl: 'https://app01.int.tsg.hmhco.com/edcore/eds-login',
  edsAuthorizationCode:
    'https://app01.int.tsg.hmhco.com/edcore/eds-login/login/authorization-code/callback',
  authorizationApi: 'https://local.hmhone.hmhco.com:9002/api/authorization',
  restIdsGridApi: 'https://api.int.br.internal/ids-grid-api',
  susAuth: '/api/authorization',
  ampLogin: 'https://local.hmhone.hmhco.com:9002/ui/login/',
  ltiCache: 'https://api.int.eng.hmhco.com/lti-deep-linking/cache',
};

module.exports = localHnmConfig;
