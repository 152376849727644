const prodHnmConfig = {
  name: 'prod',
  edsLoginLandingPage: 'https://platform.heinemann.com/ui/login/landing-page',
  contentShareAppUrl: 'https://platform-content-share.heinemann.com/',
  edsLogoutUrl: 'https://api.heinemann.com/login/logout',
  edsPostLogoutRedirectUrl: 'https://platform.heinemann.com/',
  flightRosteredLoginUrl: 'https://platform.heinemann.com/ui/login/',
  edsLoginClientId: '2cc7842e-ed1b-401e-b203-c2756a26d795',
  edsLoginUrl: 'https://api.heinemann.com/login/login/initializeUri',
  edsLoginIssuer: 'https://platform.authorization.heinemann.com',
  edsGraphUrl: 'https://api.heinemann.com/graphql',
  edsLtiUrl: 'https://api.heinemann.com/lti/lti',
  edLtiUrl: 'https://papi.hmhco.com/lti-platform',
  rceProtocolHost: 'https://www.hmhco.com',
  planktonBaseUrl: 'https://d16qzots3do3wk.cloudfront.net/',
  isPlanktonPreview: false,
  edsContentShareClientId: 'a8bde1fa-076d-4092-85e0-f39a82adf425',
  edsContentShareInitializeUrl:
    'https://api.heinemann.com/lti/lti/launch/share-link',
  edsContentShareAuthorizeUrl: 'https://api.heinemann.com/lti/lti/authorize',
  edsContentShareTokenUrl: 'https://api.heinemann.com/lti/lti/token',
  licensesRemoteEntrypoint: 'http://localhost:9999',
  restUdsApi: 'https://papi.hmhco.com/uds/',
  featureFlagURL:
    'https://platform.heinemann.com/featureFlagsCohorts.flight.json',
  heinemannBaseURL: 'https://platform.heinemann.com',
  doTheMathBaseUrl: 'https://platform.heinemann.com/ui/login',
  requestSigninDetails: '/ui/login/request-signin-details/',
  liftRoot: 'https://platform.heinemann.com/ui/',
  jasperReporting: 'https://papi.heinemann.com/jasperserver-pro',
  ampLoggedInURL: 'https://www.hmhco.com/ui/logged-in/',
  ampSignedInURL: 'https://platform.heinemann.com/ui/signed-in/',
  edsInitUrl: 'https://hapi.hmhco.com/edcore/eds-login/login/initializeUri',
  wormhole: 'https://papi.hmhco.com/wh-api/graphql',
  wormholek8s: 'https://app01.tsg.hmhco.com/wormhole-api/graphql',
  apiBaseURL: 'https://www.hmhco.com',
  restStagingProcessor: 'https://papi.hmhco.com/idm-staging-processor',
  flightLogin: `https://platform.heinemann.com/ui/login/`,
  EDSLoginUrl: 'https://hapi.hmhco.com/edcore/eds-login',
  edsAuthorizationCode:
    'https://api.heinemann.com/edcore/eds-login/login/authorization-code/callback',
  authorizationApi: 'https://www.hmhco.com/api/authorization',
  restIdsGridApi: 'https://papi.hmhco.com/ids-grid-api',
  susAuth: 'https://www.hmhco.com/api/authorization',
  ampLogin: 'https://platform.heinemann.com/ui/login/',
  ltiCache: 'https://papi.hmhco.com/lti-deep-linking/cache',
};

module.exports = prodHnmConfig;
