import { getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import getUserContext from './getHcpUserContext';
import { getUiPlatform } from '../../../../environments/getUiPlatform';

/**
 * We want to give the option of getting the uiPlatform/currentConfiguration
 * from the userContext. This replaces integratiomUserType
 * The source of the platform information is `currentConfiguration.js`
 */

export const appendCurentPlatform = userContext => {
  return { ...userContext, uiPlatform: getUiPlatform() };
};

/**
 * Now at least it's a bit more clear that when on PLATFORMS.FLIGHT the function
 * should be called asynchronously
 */

export const getHcpContextWrapper = platform => {
  const config = getConfigForCurrentEnv(platform);
  const url = config.edsGraphUrl;

  // all current implementations require groupMembership
  const { request } = getUserContext(url, true);
  return new Promise(res => {
    request().then(response => {
      const contextWithUiPlatform = appendCurentPlatform(response);
      res(contextWithUiPlatform);
    });
  });
};
