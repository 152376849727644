import { mapUser, mapUserAndTransformGroupMembership } from '@hnm/utils';
import {
  getUserContextQuery,
  getUserContextQueryWithGroupMembershipDealer2,
  getUserContextQueryWithGroupMembershipDealer1,
} from './queries';

export const getMapper = withGroupMembership => {
  if (withGroupMembership) {
    return mapUserAndTransformGroupMembership;
  }
  return mapUser;
};

export const getDealerBasedQuery = tenantId => {
  /**
   * tenantId (tenant_id) 2 means Lucy Calkins users (dealer1/individual login)
   * any other tenantId means we need to use dealer2 (institutional login)
   */
  if (tenantId !== 2) {
    return getUserContextQueryWithGroupMembershipDealer2;
  }
  return getUserContextQueryWithGroupMembershipDealer1;
};

export const getQuery = (auth, withGroupMembership) => {
  if (withGroupMembership) {
    return getDealerBasedQuery(
      Number(auth?.authContext?.idToken?.claims?.tenant_id),
    );
  }
  return getUserContextQuery;
};

const queryHelper = (auth, withGroupMembership) => {
  return {
    query: getQuery(auth, withGroupMembership),
    variables: { id: auth.userId.toString() },
    mapper: getMapper(withGroupMembership),
  };
};

export default queryHelper;
