import React from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { bool, func, string } from 'prop-types';
import { PLATFORMS, usePlatform } from '@hmhco/platform-helper';
import useStyles from '../../../RegisterUser/Input.styles';
import { AUTO_COMPLETE } from '../../../../constants/constants';

const OrderNumber = ({
  orderNumber,
  error,
  isTouched,
  handleChange,
  handleBlur,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const platform = usePlatform();
  const isError = Boolean(isTouched && error);
  const errorMessage = isTouched && error ? formatMessage({ id: error }) : '';

  return (
    <TextField
      value={orderNumber}
      onChange={handleChange}
      onBlur={handleBlur}
      helperText={errorMessage}
      error={isError}
      inputProps={{
        className: classes.input,
      }}
      label={formatMessage({
        id:
          platform === PLATFORMS.FLIGHT
            ? 'registerPage.label.orderNumber.flight'
            : 'registerPage.label.orderNumber',
      })}
      id="orderNumber"
      name="orderNumber"
      required
      autoComplete={AUTO_COMPLETE.ONE_TIME_CODE}
    />
  );
};

OrderNumber.propTypes = {
  orderNumber: string,
  error: string,
  isTouched: bool,
  handleChange: func,
  handleBlur: func,
};

OrderNumber.defaultProps = {
  orderNumber: undefined,
  error: undefined,
  isTouched: undefined,
  handleChange: undefined,
  handleBlur: undefined,
};

export default OrderNumber;
