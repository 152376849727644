import { createGraphClient } from '@hnm/graph-client';
import { getAuth } from '@hnm/session-context';
import queryHelper from './queryHelper';

/**
 * It seems like no remaining container implementations
 * that get the HCP context require it to not have group membership
 * Leaving the implementation in, in case that's going to be changed
 * Otherwise, please remove withGroupMembership implementation later
 */

const getUserContext = (url, withGroupMembership = false) => {
  const auth = getAuth();
  const { request } = createGraphClient(url);

  const { query, variables, mapper } = queryHelper(auth, withGroupMembership);

  return {
    request: () =>
      request(query, variables).then(result => {
        if (result?.getUserById?.users) {
          return result.getUserById.users.map(mapper)[0];
        }
        return undefined;
      }),
  };
};

export default getUserContext;
