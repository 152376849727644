import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'ForgotPasswordButtons' })(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 'var(--ebl-s2)',
  },
  button: {
    marginLeft: 0,
    marginRight: 0,
  },
}));
