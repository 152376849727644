import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { ALERT_SIZE, HcpAlert, SEVERITY } from '@hmhco/hcp-alert';
import logErrorWithContext from '@hmhco/client-monitoring/src/context/logErrorWithContext';
import { usePlatform } from '@hmhco/platform-helper';
import changePasswordApi from '../../../../api/changePasswordApi';
import Username from '../../../NewPasswordPage/Username';
import usernameValidator from '../../../../util/usernameValidator';
import passwordValidator from '../../../../util/passwordValidator';
import formDataValidator from '../../../../util/formDataValidator';
import Password from '../../../NewPasswordPage/Password';
import { PLATFORM_IDS, QUERY_PARAMS } from '../../../../constants/constants';
import TenantPickerLayout from '../../Layout/TenantPickerLayout';
import Logo from '../../Logo/Logo';
import useStyles from './NewPasswordPage.styles';
import RequiredFieldsNote from '../../RequiredFieldsNote/RequiredFieldsNote';

const NewPasswordPage = () => {
  const { classes } = useStyles();
  const platform = usePlatform();
  const [createNewPasswordError, setCreateNewPasswordError] = useState(false);
  const [username, setUsername] = useState(undefined);
  const [newPassword, setNewPassword] = useState(undefined);
  const [verifyPassword, setVerifyPassword] = useState(undefined);
  const { isUsernameValid, usernameErrorMessageId } = usernameValidator(
    username,
  );
  const { isPasswordValid, passwordErrorMessageId } = passwordValidator(
    newPassword,
  );
  const {
    isFormValid,
    passwordMatchesUsernameErrorMessageId,
    passwordsDoNotMatchErrorMessageId,
  } = formDataValidator(
    newPassword,
    verifyPassword,
    username,
    isUsernameValid,
    isPasswordValid,
  );
  const { changePassword } = changePasswordApi();
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get(QUERY_PARAMS.TOKEN);
  const connection = queryParams.get(QUERY_PARAMS.CONNECTION);
  const { formatMessage } = useIntl();
  const usernamePasswordError = passwordMatchesUsernameErrorMessageId
    ? formatMessage({
        id: passwordMatchesUsernameErrorMessageId,
      })
    : undefined;
  const verifyPasswordErrorMessage = passwordsDoNotMatchErrorMessageId
    ? formatMessage({
        id: passwordsDoNotMatchErrorMessageId,
      })
    : undefined;
  const passwordLabel = formatMessage({
    id: 'tenantPicker.newPassword.newPasswordLabel',
  });
  const reEnterPasswordLabel = formatMessage({
    id: 'tenantPicker.newPassword.reEnterNewPasswordLabel',
  });
  const passwordErrorMessage = passwordErrorMessageId
    ? formatMessage({
        id: passwordErrorMessageId,
      })
    : undefined;

  const requestPasswordUpdate = event => {
    event.preventDefault();

    const payload = {
      username,
      token,
      password: newPassword,
      platformId: PLATFORM_IDS[platform],
    };
    changePassword(payload)
      .then(() => {
        setCreateNewPasswordError(false);
        history.push({
          pathname: '/tenant-picker/',
          search: `?${QUERY_PARAMS.CONNECTION}=${connection}&${QUERY_PARAMS.FROM}=${QUERY_PARAMS.PASSWORD_RESET}`,
        });
      })
      .catch(error => {
        setCreateNewPasswordError(true);
        logErrorWithContext('Login app - Error in creating new password', [
          { key: 'errorMessage', value: error },
        ]);
      });
  };

  const handleUsernameChange = event => setUsername(event.target.value);
  const handlePasswordChange = event => setNewPassword(event.target.value);
  const handleVerifyPasswordChange = event =>
    setVerifyPassword(event.target.value);

  return (
    <TenantPickerLayout>
      <header>
        <Logo />
        {createNewPasswordError && (
          <HcpAlert
            size={ALERT_SIZE.SMALL}
            severity={SEVERITY.ERROR}
            title={formatMessage({
              id: 'tenantPicker.errorTitle',
            })}
            subtitle={formatMessage({
              id: 'newPassword.createNewPassword.error',
            })}
            className={classes.error}
          />
        )}
        <div className={classes.heading}>
          <Typography component="h1" variant="h3">
            <FormattedMessage id="login.newPassword.pageTitle" />
          </Typography>
          <RequiredFieldsNote />
        </div>
      </header>
      <main className={classes.main}>
        <Username
          handleUsernameChange={handleUsernameChange}
          errorMessageId={usernameErrorMessageId}
        />
        <Password
          dataTestId="new-password-password"
          handlePasswordChange={handlePasswordChange}
          errorMessage={passwordErrorMessage || usernamePasswordError}
          passwordLabel={passwordLabel}
          elementId="new-password-enter-password"
        />
        <Typography variant="body2">
          <FormattedMessage id="tenantPicker.passwordRequirements" />
        </Typography>
        <Password
          dataTestId="new-password-validate-password"
          handlePasswordChange={handleVerifyPasswordChange}
          errorMessage={verifyPasswordErrorMessage}
          passwordLabel={reEnterPasswordLabel}
          elementId="new-password-reenter-password"
        />
        <div className={classes.buttonContainer}>
          <Button
            disabled={!isFormValid}
            onClick={requestPasswordUpdate}
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
          >
            <FormattedMessage id="login.newPassword.submitButton" />
          </Button>
        </div>
      </main>
    </TenantPickerLayout>
  );
};

export default NewPasswordPage;
