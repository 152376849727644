import React from 'react';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { ALERT_SIZE, HcpAlert, SEVERITY } from '@hmhco/hcp-alert';
import useStyles from './RequestSignInDetailsHeading.styles';
import {
  getIsRequestSigninDisabled,
  getShouldRenderErrorScreen,
  getShouldRenderSuccessScreen,
} from '../../../../store/selectors/RequestSigninPageState';
import RequiredFieldsNote from '../../RequiredFieldsNote/RequiredFieldsNote';

const getTitleId = renderSuccessState => {
  if (renderSuccessState) {
    return 'tenantPicker.requestSiginDetails.successTitle';
  }
  return 'tenantPicker.requestSiginDetails.title';
};

const RequestSignInDetailsInformationHeader = () => {
  const { cx, classes } = useStyles();
  const { formatMessage } = useIntl();
  const requestSigninDisabled = useSelector(getIsRequestSigninDisabled);
  const renderErrorState = useSelector(getShouldRenderErrorScreen);
  const renderSuccessState = useSelector(getShouldRenderSuccessScreen);
  const titleId = getTitleId(renderSuccessState);

  return (
    <div className={classes.root}>
      <div
        className={cx(classes.errors, {
          [classes.hasError]: renderErrorState || requestSigninDisabled,
        })}
      >
        {renderErrorState && (
          <HcpAlert
            size={ALERT_SIZE.SMALL}
            severity={SEVERITY.ERROR}
            title={formatMessage({
              id: 'tenantPicker.errorTitle',
            })}
            subtitle={formatMessage({
              id: 'login.requestSiginDetails.errorMessage',
            })}
            action={null}
          />
        )}
        {requestSigninDisabled && (
          <HcpAlert
            size={ALERT_SIZE.SMALL}
            severity={SEVERITY.ERROR}
            title={formatMessage({
              id: 'tenantPicker.errorTitle',
            })}
            subtitle={formatMessage({
              id: 'login.RequestSignInDetails.disabledMessage',
            })}
            action={null}
          />
        )}
      </div>
      <Typography component="h1" variant="h3">
        <FormattedMessage id={titleId} />
      </Typography>
      {!requestSigninDisabled && !renderSuccessState && !renderErrorState && (
        <RequiredFieldsNote />
      )}
    </div>
  );
};

export default RequestSignInDetailsInformationHeader;
