import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'ForgotPasswordHeading' })({
  root: {
    paddingBottom: 'var(--ebl-s3)',
  },
  error: {
    marginBottom: 'var(--ebl-s3)',
  },
});
