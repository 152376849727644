import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'RequiredFieldsNote' })(() => ({
  root: {
    display: 'flex',
    gap: 'var(--ebl-s2)',
  },
  icon: {
    paddingTop: '0.188rem',
  },
}));
