import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useGetConnectionParam from './getConnectionParam.hook';
import getOrgInfoClient from '../api/getOrgInfo';
import {
  setForgotPasswordDisabledFlag,
  setForgotPasswordOrgError,
  setOrgName,
  setIsInternational,
} from '../store/actions/ForgotPasswordActions';
import { setRequestSigninDetailsDisabledFlag } from '../store/actions/RequestSigninActions';

function useBootstrapOrgInfo() {
  const dispatch = useDispatch();
  const orgPid = useGetConnectionParam();
  useEffect(() => {
    const { getOrgInfo } = getOrgInfoClient();
    getOrgInfo(orgPid)
      .then(orgInfo => {
        dispatch(setOrgName(orgInfo.name));
        dispatch(
          setIsInternational(
            Boolean(orgInfo.countryCode && orgInfo.countryCode !== 'US'),
          ),
        );
        if (!orgInfo.forgotPasswordIsEnabled) {
          dispatch(setForgotPasswordDisabledFlag());
          dispatch(setRequestSigninDetailsDisabledFlag());
        }
      })
      .catch(() => {
        dispatch(setForgotPasswordOrgError());
      });
  }, [orgPid]);
}

export default useBootstrapOrgInfo;
