import { Authorize, getPkceCodeVerifier } from '@hmh/eds-login-js';
import { getClientId, NON_SSO_ISSUER } from '../constants/constants';
import { getConfig } from '../api/buildAuthenticationUrl';
import { getSignedInUrl } from '../api/buildAuthenticationUrlHnm';

export const redirectToInitialiseUri = (connection, loginHint) => {
  const { edsInitUrl } = getConfig();
  getPkceCodeVerifier('S256').then(({ challenge, verifier }) => {
    sessionStorage.setItem('verifier', verifier);
    sessionStorage.setItem('challenge', challenge);
  });

  const authorize = new Authorize(getClientId(), NON_SSO_ISSUER, edsInitUrl, {
    connection,
    targetLinkUri: getSignedInUrl(),
    login_hint: loginHint,
  });

  authorize.getThirdPartyLoginUri({ loginHint }).then(loginInitUri => {
    window.location = loginInitUri;
  });
};

export default {};
