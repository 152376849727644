import React from 'react';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { PLATFORMS, usePlatform } from '@hmhco/platform-helper';
import { useSelector, useDispatch } from 'react-redux';
import useStyles from './ForgotPasswordButtons.styles';
import forgotPasswordApi from '../../../../api/forgotPassword';
import {
  getForgotPasswordUsername,
  getIsForgotPasswordUsernameValid,
  getCanSubmitUsername,
} from '../../../../store/selectors/ForgotPasswordPageState';
import useGetConnectionParam from '../../../../hooks/getConnectionParam.hook';
import {
  setForgotPasswordError,
  setForgotPasswordLoading,
  setForgotPasswordSuccess,
} from '../../../../store/actions/ForgotPasswordActions';

const ForgotPasswordButtons = () => {
  const platform = usePlatform();
  const canSubmitUsername = useSelector(getCanSubmitUsername);
  const history = useHistory();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const currentUsername = useSelector(getForgotPasswordUsername);
  const connection = useGetConnectionParam();
  const canRequestPasswordChange = useSelector(
    getIsForgotPasswordUsernameValid,
  );

  const onSubmit = async event => {
    event.preventDefault();
    const { forgotPassword, forgotPasswordHnm } = forgotPasswordApi();
    try {
      dispatch(setForgotPasswordLoading());
      if (platform === PLATFORMS.FLIGHT) {
        await forgotPasswordHnm({
          username: currentUsername,
          districtId: connection,
        });
      } else {
        await forgotPassword({
          username: currentUsername,
          districtId: connection,
        });
      }
      dispatch(setForgotPasswordSuccess());
    } catch (error) {
      dispatch(setForgotPasswordError());
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.buttons}>
      <Button
        variant="outlined"
        size="small"
        onClick={goBack}
        role="link"
        className={classes.button}
      >
        <FormattedMessage id="login.forgotPassword.backButton" />
      </Button>
      {canSubmitUsername && (
        <Button
          disabled={!canRequestPasswordChange}
          variant="contained"
          color="primary"
          type="submit"
          size="small"
          onClick={onSubmit}
          className={classes.button}
        >
          <FormattedMessage id="login.forgotPassword.submitButton" />
        </Button>
      )}
    </div>
  );
};

export default ForgotPasswordButtons;
