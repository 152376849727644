import { createAxiosCancelable } from '@hmhco/core-network/src/axiosHelpers';
import requestHelper from '@hmhco/core-network/src/utils/requestHelper';
import { getConfigForCurrentEnv } from '@hmhco/amp-core-env';
import { currentRouteIntegration } from '../../../../../environments/environment';

const signUpUrl = '/user/signupUser';

const registerNewDistrictAdministratorApi = (pactConfig = {}) => {
  const { authorizationApi } = getConfigForCurrentEnv(
    currentRouteIntegration(),
  );
  const { client, cancelToken, isCancel } = createAxiosCancelable({
    includeAuth: false,
  });
  const request = requestHelper({
    client,
    cancelToken,
    isCancel,
    baseURL: authorizationApi,
    pactConfig,
  });
  return {
    registerNewDistrictAdministrator: ({
      firstName,
      lastName,
      username,
      password,
      districtId,
      secret,
      token,
      platformId,
      brandId,
    }) => {
      return request.post(signUpUrl, {
        firstName,
        lastName,
        username,
        password,
        districtId,
        secret,
        token,
        platformId,
        brandId,
      });
    },
  };
};

export default registerNewDistrictAdministratorApi;
