import { getEnvironment } from '@hmhco/get-environment/src/getEnvironment';
import { PLATFORMS } from '@hmhco/platform-helper';
import localCohorts from '../../config/featureFlagsCohorts.local.config';
import localFlightCohorts from '../../config/featureFlagsCohorts.flight.local.config';

export const FEATURE_FLAGS_KEY = 'featureFlags';

const env = getEnvironment();

export const mergeCohorts = (cohorts, platform) => {
  return [
    ...cohorts,
    ...(platform === PLATFORMS.FLIGHT ? localFlightCohorts : localCohorts),
  ];
};

const setFeatureFlags = (userData, fflip, cohorts, demoUser) => {
  const flags = new Set([]);
  const allCohorts = [undefined, 'local', 'devCert'].includes(env)
    ? mergeCohorts(cohorts, userData.platform)
    : cohorts;
  allCohorts.forEach(cohort => {
    const {
      filter: { value, target },
    } = cohort;
    if (
      fflip.isFeatureEnabledForUser(target, {
        userData,
        value,
      })
    ) {
      flags.add(cohort.flag);
    }
  });
  if (demoUser) {
    flags.add('demoEntitlements');
  }
  localStorage.setItem(FEATURE_FLAGS_KEY, JSON.stringify([...flags]));
};

export default setFeatureFlags;
