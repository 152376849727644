import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'CreateAccountButton' })(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 0,
    marginRight: 0,
  },
}));
