import {
  ENVIRONMENTS,
  PLATFORMS,
} from '../../../../environments/globalConstants';
import { getUiPlatform } from '../../../../environments/getUiPlatform';
import configMap from './configMap';

/**
 * Returns a config for the passed environment and platform
 * This library exposes PLATFORMS and ENVIRONMENTS objects you can use
 *
 * @param {string} env name of the environment, expected: local, int, cert devCert, or prod
 * @param {string} platform the platform the config is for, platforms are defined in globalConstants and exported from this library as PLATFORMS
 *
 * @returns {object} a config object (URLs) for the passed environment, and the passed platform
 */

export const getConfig = (env, platform) => {
  const envValues = Object.values(ENVIRONMENTS);
  const platformValues = Object.values(PLATFORMS);

  if (!envValues.includes(env)) {
    throw new Error(
      `Environment: "${env}" is not supported, please use one of the following: [${envValues}]`,
    );
  }

  if (!platformValues.includes(platform)) {
    throw new Error(
      `Platform: "${platform}" is not supported, please use one of the following: [${platformValues}]`,
    );
  }

  return configMap[env][platform];
};

export const getCurrentEnv = () =>
  window.HMH_ENV ||
  process.env.RUNTIME_ENV ||
  process.env.STORYBOOK_ENV ||
  window.RUNTIME_ENV;

/**
 * Returns the config for the current environment and an optional passed platform
 * If platform is not passed, it uses currentConfiguration from the environment file
 * This library exposes PLATFORMS and ENVIRONMENTS objects you can use
 *
 * @param {string} platform - optional - a platform out of globalConstants.js (or exported from this library as PLATFORMS)
 * @returns {object} a config object (URLs) for the current environment, for the current platform
 */
export const getConfigForCurrentEnv = (platform = getUiPlatform()) => {
  return getConfig(getCurrentEnv(), platform);
};

export { PLATFORMS, ENVIRONMENTS };
