import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'NewPasswordPage' })(() => ({
  heading: {
    paddingBottom: 'var(--ebl-s3)',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& > *': {
      marginBottom: 'var(--ebl-s2) !important',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 0,
    marginRight: 0,
  },
  error: {
    marginBottom: 'var(--ebl-s3)',
  },
}));
