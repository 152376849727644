import { useMemo } from 'react';
import ctsDefaultTheme from '@hmhco/cts-default-theme';
import { PLATFORM_THEMES, PLATFORM_BASE_NAME } from '../constants';
import getPlatform from '../getPlatform';
import { PLATFORMS } from '../../../../../environments/globalConstants';

/**
 * @name getCustomConfig
 * @author EnrichEd
 * @summary returns custom configuration object for the platform provided. As of 2023 ED does not need an app config.
 * @param {Object} appConfig
 * @param {String} platformIdentifier
 */
const getCustomConfig = (appConfig, platformIdentifier) => {
  if (platformIdentifier === PLATFORMS.ED) {
    return {};
  }
  return appConfig[platformIdentifier] || {};
};

/**
 * @name useConfiguration
 * @author EnrichEd
 * @summary Gets custom configuration for platform. Use this at the app level once. Then use usePlatform hook to consume in children.
 * @example @hmhco/licenses App.js
 */
const useConfiguration = (appCustomConfig = {}, ampBasename = '') =>
  useMemo(() => {
    const platform = getPlatform();
    const theme = PLATFORM_THEMES[platform] || ctsDefaultTheme;
    const customConfig = getCustomConfig(appCustomConfig, platform);
    const basename = PLATFORM_BASE_NAME[platform] || ampBasename;

    return [platform, theme, customConfig, basename];
  }, [appCustomConfig, ampBasename]);

export default useConfiguration;
